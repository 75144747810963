import { FC, useMemo } from 'react';
import { mapWithIndex as mapA, takeLeft } from 'fp-ts/lib/ReadonlyArray';
import { pipe } from 'fp-ts/lib/function';
import {
  chain,
  fromNullable,
  fromPredicate,
  map,
  match,
  toUndefined,
} from 'fp-ts/lib/Option';

import { Box, Typography } from '@oresundsbron/bridge-ui';
import { cx } from 'class-variance-authority';
import { useLinks } from '../hooks/useLinks';
import { reduceWithIndex } from 'fp-ts/lib/Array';
import { DefaultLink } from '~/components/Links/DefaultLink';

export const Breadcrumbs: FC<{
  id: string;
  invert?: boolean;
  className?: string;
  addRight?: { path: string; title: string }[];
  addLeft?: { path: string; title: string }[];
}> = ({ id, invert, className }) => {
  const { getLink, findLink } = useLinks();

  const crumbs = useMemo(
    () =>
      pipe(
        getLink(id),
        fromNullable,
        map((c) =>
          pipe(
            c.slugs,
            reduceWithIndex([] as Array<typeof c>, (i, res) =>
              pipe(
                c.slugs,
                takeLeft(i + 1),
                (s) => s.join('/'),
                (s) => `/${s}`,
                findLink,
                chain(fromPredicate((x) => !x.skip)),
                match(
                  () => res,
                  (l) => [...res, l]
                )
              )
            )
          )
        ),
        map(mapA((_, l) => ({ path: l.path, title: l.title }))),
        chain(fromPredicate((x) => x.length > 1)),
        toUndefined
      ),
    [id, getLink, findLink]
  );

  return crumbs ? (
    <Box
      as={'ul'}
      itemScope
      itemType="https://schema.org/BreadcrumbList"
      className={cx('flex flex-wrap gap-1', className)}
    >
      {crumbs.map(({ path, title }, i) => (
        <Box
          key={path}
          as="li"
          className="flex items-center gap-1"
          itemProp="itemListElement"
          itemScope
          itemType="https://schema.org/ListItem"
        >
          {i !== crumbs.length - 1 ? (
            <DefaultLink
              itemProp="item"
              itemType="https://schema.org/WebPage"
              href={path}
              color={!!invert ? 'inherit' : 'default'}
              className={cx(!!invert ? 'text-white' : undefined, 'underline')}
            >
              <span itemProp="name">{title}</span>
            </DefaultLink>
          ) : (
            <Typography
              as="span"
              className={cx(!!invert ? 'text-neutral-400' : 'text-neutral-500')}
            >
              <span itemProp="name">{title}</span>
            </Typography>
          )}
          {i !== crumbs.length - 1 ? (
            <Box
              as="span"
              aria-hidden
              className={cx(!!invert ? 'text-neutral-400' : 'text-neutral-500')}
            >
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                fill="currentColor"
              >
                <mask
                  id="mask0_8302_19744"
                  style={{ maskType: 'alpha' }}
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="16"
                  height="16"
                >
                  <rect width="16" height="16" fill="currentColor" />
                </mask>
                <g mask="#mask0_8302_19744">
                  <path
                    d="M5.79954 11.5333C5.67732 11.4111 5.61621 11.2555 5.61621 11.0667C5.61621 10.8778 5.67732 10.7222 5.79954 10.6L8.39954 7.99999L5.79954 5.39999C5.67732 5.27777 5.61621 5.12221 5.61621 4.93333C5.61621 4.74444 5.67732 4.58888 5.79954 4.46666C5.92177 4.34444 6.07732 4.28333 6.26621 4.28333C6.4551 4.28333 6.61066 4.34444 6.73288 4.46666L9.79954 7.53333C9.86621 7.59999 9.91354 7.67221 9.94154 7.74999C9.9691 7.82777 9.98288 7.9111 9.98288 7.99999C9.98288 8.08888 9.9691 8.17221 9.94154 8.24999C9.91354 8.32777 9.86621 8.39999 9.79954 8.46666L6.73288 11.5333C6.61066 11.6555 6.4551 11.7167 6.26621 11.7167C6.07732 11.7167 5.92177 11.6555 5.79954 11.5333Z"
                    fill="currentColor"
                  />
                </g>
              </svg>
            </Box>
          ) : null}
          <meta itemProp="position" content={(i + 1).toString()} />
        </Box>
      ))}
    </Box>
  ) : null;
};
