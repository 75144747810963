import { pageGridMaxWidthWrapper } from '~/modules/ContentfulGrid/Column';

interface VideoBannerProps {
  url: string;
}
const VideoBanner = ({ url }: VideoBannerProps) => {
  return (
    <video
      src={url}
      autoPlay
      loop
      muted
      playsInline
      className={`absolute inset-0 mx-auto h-full w-full object-cover ${pageGridMaxWidthWrapper}`}
    />
  );
};

export default VideoBanner;
